import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { base_url } from "../../utils/base_url";

const Nav = () => {
  const [headerMenu, setHeaderMenu] = useState(null);
  const [hoveredMenuId, setHoveredMenuId] = useState(null);
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}/api/menus/?populate=*`);
        const data = await response.json();
        const header = data.data.find((menu) => menu.attributes.slug === "Header");
        if (header && isMounted) {
          setHeaderMenu(header);
        } else {
          console.error("Header menu not found in the API response");
        }
      } catch (error) {
        console.error("Error fetching header menu data:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchServices = async () => {
      try {
        const response = await fetch(`${base_url}/api/services/?populate=*`);
        const data = await response.json();
        if (isMounted) {
          setServiceData(data.data);
        }
      } catch (error) {
        console.error("Error fetching services data:", error);
      }
    };

    fetchServices();

    return () => {
      isMounted = false;
    };
  }, []);

  const renderStaticDropdown = () => {
    return (
      <ul className="submenu" style={{ position: 'absolute' , height:'110px' , width:'210px', backgroundColor: 'black' }} onMouseLeave={() => setHoveredMenuId(null)}>
        {serviceData.map((service) => (
          <li key={service.id}>
            <Link to={`/service/${service.attributes.slug}`}>{service.attributes.title}</Link>
          </li>
        ))}
      </ul>
    );
  };

  const renderMenu = () => {
    return (
      <ul className="mainmenu">
        {headerMenu &&
          headerMenu.attributes.items.data.map((menuItem) => (
            <li
              key={menuItem.id}
              className="nav-item"
              onMouseEnter={() => setHoveredMenuId(menuItem.id)}
              onMouseLeave={() => setHoveredMenuId(null)}
            >
              <Link className="nav-link" to={menuItem.attributes.url}>
                {menuItem.attributes.title}
              </Link>
              {menuItem.attributes.title === "Services" && hoveredMenuId === menuItem.id && renderStaticDropdown()}
            </li>
          ))}
      </ul>
    );
  };

  return <>{headerMenu ? renderMenu() : null}</>;
};

export default Nav;

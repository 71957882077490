import React, { useState } from "react";
import SEO from "../../common/SEO";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";

function WebDevelopmentForm() {
  const [result, showResult] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [brandIdentityChecked, setBrandIdentityChecked] = useState(false);

  const Result = () => {
    return (
      <p className="success-message">
        Your Message has been successfully sent. We will contact you soon.
      </p>
    );
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "marketingCheckbox") {
      setMarketingChecked(checked);
    } else if (name === "brandIdentityCheckbox") {
      setBrandIdentityChecked(checked);
    }
  };

  const sendQuote = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    try {
      const response = await fetch("http://api.seqnetworks.com/sendQuote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formObject),
      });

      if (response.ok) {
        showResult(true);

        setTimeout(() => {
          showResult(false);
        }, 5000);
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }

    e.target.reset();
  };

  return (
    <>
    <SEO title="Web development quote" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

    <div className="service-area pt--20">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="text-center border border-dark rounded">
              Get a Quote
            </h3>
            <form
              className="contact-form-1 pt--20"
              action=""
              onSubmit={sendQuote}
            >
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  placeholder="FIRST NAME"
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  placeholder="LAST NAME"
                  required
                />
              </div>
              <div className="form-group">
                <input type="email" name="email" placeholder="EMAIL" required />
              </div>

              <div className="form-group">
                <input type="text" name="phone" placeholder="PHONE" required />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="company"
                  placeholder="COMPANY"
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="role"
                  placeholder="YOUR ROLE"
                  required
                />
              </div>

              <div className="form-group">
                <label>WHICH BEST DESCRIBES YOUR PROJECT?</label>
                <select className="bg-transparent mb-3" name="description">
                  <option>Select one...</option>
                  <option value="New website">New website</option>
                  <option value="Website redesign">Website redesign</option>
                  <option value="Website migration3">Website migration</option>
                  <option value="Website Development Only3">
                    Website Development Only
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <label>HOW ELSE CAN WE HELP?</label>
              <br />
              <div className="form-group">
                <input
                  className="form-check"
                  type="checkbox"
                  name="marketingCheckbox"
                  id="flexCheckDefault"
                  checked={marketingChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  MARKETING
                </label>
              </div>
              <div className="form-group">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="brandIdentityCheckbox"
                  id="flexCheck"
                  checked={brandIdentityChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheck">
                  BRAND IDENTITY
                </label>
              </div>

              <div className="form-group">
                <textarea
                  name="moreInfo"
                  placeholder="TELL US MORE..."
                  required
                ></textarea>
              </div>

              <div className="form-group">
                <button className="btn-default btn-large">SUBMIT</button>
              </div>

              <div className="form-group">{result ? <Result /> : null}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <FooterOne />
    </main>
    </>
  );
}
export default WebDevelopmentForm;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Logo from "../../elements/logo/Logo";
import { base_url } from "../../utils/base_url";
import { toast } from 'react-toastify';


const FooterOne = () => {
  const [footerData, setFooterData] = useState({});
  const [logo, setLogo] = useState("");
  const [servicesMenu, setServicesMenu] = useState([]);
  const [companyMenu, setCompanyMenu] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    fetch(`${base_url}/api/footer?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.data) {
          const data = responseData.data.attributes;
          setFooterData(data);
          setLogo(data.footerLogo.data.attributes.url);
        }
      })
      .catch((error) => {
        console.error("Error fetching footer data:", error);
      });

    fetch(`${base_url}/api/menus/?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.data) {
          const menus = responseData.data;
          const services = menus.find((menu) => menu.attributes.title === "Services");
          const company = menus.find((menu) => menu.attributes.title === "Company");
          if (services) {
            setServicesMenu(services.attributes.items.data);
          }
          if (company) {
            setCompanyMenu(company.attributes.items.data);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching menu data:', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${base_url}/api/subscribe-lists`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          email: email
        }
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Newsletter subscription successful:', data);
        toast.success('Subscription Successful!');
        setEmail("");
      })
      .catch(error => {
        console.error('Error subscribing to newsletter:', error);
        toast.error('Subscription Failed!');
      });
  };

  return (
    <>
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Logo Section */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="logo">
                    {logo && <Logo image={`${base_url}${logo}`} image2={`${base_url}${logo}`} />}
                  </div>
                  {/* Footer Content */}
                  {footerData.footerContent && (
                    <div className="footer-content" style={{ fontSize: '22px', paddingTop: '20px', paddingRight: '20px', fontWeight: '30px'}} dangerouslySetInnerHTML={{ __html: footerData.footerContent }} />
                  )}
                </div>
              </div>

              {/* Services Section */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="title-section">
                    <div className="widget-menu-bottom">
                      <h4 className="title">Services</h4>
                    </div>
                    {/* Rendering submenuItems */}
                    <div className="widget-menu-top">
                      <div className="inner">
                        <ul className="footer-link link-hover">
                          {servicesMenu.map((item) => (
                            <li key={item.id}>
                              <Link to={item.attributes.url}>{item.attributes.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                   <div className="title-section">
                    <div className="widget-menu-bottom">
                      <h4 className="title">Company</h4>
                    </div>
                    {/* Rendering submenuItems */}
                    <div className="widget-menu-top">
                      <div className="inner">
                        <ul className="footer-link link-hover">
                          {companyMenu.map((item) => (
                            <li key={item.id}>
                              <Link to={item.attributes.url}>{item.attributes.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           
              {/* Form Section */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">Join Our Mailing List</h4>
                  <div className="inner">
                    <h6 className="subtitle">{footerData.subtitle}</h6>
                    <form className="newsletter-form" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input type="email" placeholder="Enter Your Email Here" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div className="form-group">
                        <button className="btn btn-default" type="submit">
                          Submit Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;

import React, { useEffect } from 'react';

const Darkmode = () => {
    useEffect(() => {
        // Set default theme to dark mode when the component mounts
        document.querySelector('body').classList.add("dark");

        // Make sure to remove the light mode class if it was set previously
        return () => {
            document.querySelector('body').classList.remove("light");
        };
    }, []);

    const switchTheme = (e) => {
        document.querySelector('body').classList.toggle("light");
        console.log("e", e);
    }

    return (
        <button id="darkmode" onClick={(e) => switchTheme(e)}>
            <img className="light-icon" src={`${process.env.PUBLIC_URL}/images/icons/sun-01.svg`} alt="Sun images" />
            <img className="dark-icon" src={`${process.env.PUBLIC_URL}/images/icons/vector.svg`} alt="Sun images" />
        </button>
    );
}

export default Darkmode;
import React, { useState, useEffect } from 'react';
import { FiChevronUp } from "react-icons/fi";

const ScrollTop = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 300) {
                setVisible(true);
            } else if (scrolled <= 300) {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisible);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []); // Empty dependency array ensures the effect runs only once on component mount

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <div
                className="rn-back-top"
                onClick={scrollToTop}
                style={{ display: visible ? 'inline' : 'none' }}
            ><FiChevronUp /></div>
        </>
    );
}

export default ScrollTop;

import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import appleLogo from "../assets/images/apple.png";
import linuxLogo from "../assets/images/linux.png";
import windowsLogo from "../assets/images/windows.png";

const RemoteSupport = () => {
  return (
    <>
      <SEO
        title="Remote Support"
        description="Get Remote Support"
        imageUrl="https://www.seqnetworks.com/images/logo/favicon.png"
        url="https://www.seqnetworks.com/remote-support"
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Background and title*/}
        <div
          className="slider-style-1"
          style={{
            backgroundColor: "black",
            height: "200px",
            textAlign: "center",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--60 text-center">
                  <h1 className="title theme-gradient h2">Remote Support</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Detail section*/}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            {/* content section*/}
            <div className="inner text-center mt--4">
              <h3 className="title">Get Remote Support</h3>
            </div>

            <div className="main-content">
              <div className="rn-blog-area pt--40">
                <div
                  className="container"
                  style={{ backgroundColor: "#0f0f11" }}
                >
                  <div className="row mt_dec--30 text-center">
                    <div className="col-lg-4  mt-4">
                      <div className="rn-card">
                        <div className="inner">
                          <div className="thumbnail">
                            <a href="https://898.tv/6gq4e79" className="image">
                              <img
                                src={windowsLogo}
                                alt="Windows Logo"
                                style={{ width: "50%" }}
                              />
                            </a>
                          </div>
                          <div className="content">
                            <h4 className="title">Windows Users</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4  mt-4">
                      <div className="rn-card">
                        <div className="inner">
                          <div className="thumbnail">
                            <a href="https://898.tv/6gq4e79" className="image">
                              <img
                                src={appleLogo}
                                alt="apple Logo"
                                style={{ width: "40%" }}
                              />
                            </a>
                          </div>
                          <div className="content">
                            <h4 className="title">Apple Users</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4  mt-4">
                      <div className="rn-card">
                        <div className="inner">
                          <div className="thumbnail">
                            <a href="https://898.tv/6gq4e79" className="image">
                              <img
                                src={linuxLogo}
                                alt="linux Logo"
                                style={{ width: "40%" }}
                              />
                            </a>
                          </div>
                          <div className="content">
                            <h4 className="title">Linux Users</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterOne />
      </main>
    </>
  );
};
export default RemoteSupport;

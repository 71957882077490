import React, { useState, useEffect } from 'react';

const SitemapGenerator = () => {
  const [sitemap, setSitemap] = useState('');

  // Fetch the static pages (you can modify this to fetch from an API if needed)
  function getPages() {
    return [
      '/',
      '/about',
      '/contact',
      '/remote-support',
      '/service/application-services',
      '/service/managedITservces',
      '/service/webdevelopmentservice',
      '/service/salesforceimplementation'
    ];
  }

  // Generate the sitemap XML
  const generateSitemap = () => {
    const baseUrl = 'https://seqnetworks.com'; // Your website's base URL
    const pages = getPages();

    const sitemapXML = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${pages
        .map((page) => `
          <url>
            <loc>${baseUrl}${page}</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>monthly</changefreq>
            <priority>0.8</priority>
          </url>`)
        .join('')}
    </urlset>`;

    return sitemapXML;
  };

  // Set the sitemap when the component mounts
  useEffect(() => {
    const sitemapXML = generateSitemap();
    setSitemap(sitemapXML);
  }, []);

  // Provide a button to download the sitemap as an XML file
  const downloadSitemap = () => {
    const blob = new Blob([sitemap], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'sitemap.xml';
    link.click();
  };

  return (
    <div>
      <button onClick={downloadSitemap}>Download Sitemap</button>
      <pre className='text-black'>{sitemap}</pre>
    </div>
  );
};

export default SitemapGenerator;

import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";

const DefaultService = ({ pageData }) => {
  return (
    <>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        url={`https://www.seqnetworks.com/service/${pageData.slug}`}
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div
          className="slider-style-1"
          style={{
            backgroundColor: "black",
            textAlign: "center",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--45 text-center">
                  <h3 style={{ width: "35%" }} className="title theme-gradient">
                    {pageData?.title}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gap">
          <div className="container">
            <div>
              {/* Start Service Area  */}
              <div className="rn-service-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pageData?.content || "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <FooterOne />
      </main>
    </>
  );
};

export default DefaultService;

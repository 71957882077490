import React, { useEffect } from 'react';
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
const DefaultPage = ({ pageData }) => {
  useEffect(() => {
  
  }, [pageData]);  

  return (
     <>
     <SEO title="Service" />
        <main className="page-wrapper">
       <HeaderOne   btnStyle="btn-small" HeaderSTyle="header-not-transparent" /> 
    
          {/* Start Slider Area  */}
          <div
            className="slider-style-1"
            style={{
              backgroundColor: "black",
              height: "200px",
              textAlign: "center",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner pt--45 text-center">
                    <h3 style={{width : '35%'}} className="title theme-gradient">
                     {pageData.pageTitle}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Slider Area  */}
  
          {/* Start Service Area  */}
          <div className="service-area rn-section-gap">
            <div className="container">
              
              <div>
                {/* Start Service Area  */}
                <div className="rn-service-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                      <div dangerouslySetInnerHTML={{ __html: pageData.homeFAQ || ''}}/>
                      </div>
                    </div>
                  </div>
                </div>   
                         
              </div>
            </div>
          </div>
          {/* End Service Area  */}
          <FooterOne />     
        </main>
   
      </>
  );
};

export default DefaultPage;

 
import React, { useState } from "react";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. We will contact you soon.
    </p>
  );
};

function ContactForm({ formStyle }) {
  const [result, showResult] = useState(false);

 const sendEmail = async (e) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const formObject = {};
  formData.forEach((value, key) => {
    formObject[key] = value;
  });

  try {
    const response = await fetch(
      "https://cms.seqnetworks.com/api/contact-forms",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formObject),
      }
    );

    if (response.ok) {
      showResult(true);
      setTimeout(() => {
        showResult(false);
      }, 5000);
    } else {
      console.error("Failed to send email");
    }
  } catch (error) {
    console.error("Error sending email:", error);
  }

  e.target.reset();
};


  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
  <div className="form-group">
    <input type="text" name="name" placeholder="Full name" required />
  </div>

  <div className="form-group">
    <input
      type="email"
      name="email" // Ensure email field is named correctly
      placeholder="Email"
      required
    />
  </div>

  <div className="form-group">
    <input type="text" name="phone" placeholder="Phone Number" />
  </div>

  <div className="form-group">
    <input type="text" name="subject" placeholder="Subject" />
  </div>

  <div className="form-group">
    <textarea name="message" placeholder="Your Message" required></textarea>
  </div>

  <div className="form-group">
    <button className="btn-default btn-large">Submit Now</button>
  </div>

  <div className="form-group">{result ? <Result /> : null}</div>
</form>

  );
}

export default ContactForm;

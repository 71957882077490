import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Logo from "../elements/logo/Logo";
import { base_url } from "../utils/base_url";

const AboutUs = () => {
  const [data, setData] = useState(null); // Changed initial state to null
  const [logo, setLogo] = useState(null); // Changed initial state to null
  useEffect(() => {
    fetch(`${base_url}/api/about?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Uncommented logo fetching logic
    fetch(`${base_url}/api/logos?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.data && responseData.data.length > 0) {
          setLogo(responseData.data[0].attributes.logoimage.data.attributes);
        }
      })
      .catch((error) => {
        console.error("Error fetching logo data:", error);
      });
  }, []);

  if (!data) {
    return null; // Return null while data is being fetched
  }

  const AboutData = data.attributes;

  const bannerImageUrl =
    data &&
    data.attributes &&
    data.attributes.bannerImage &&
    data.attributes.bannerImage.data.attributes &&
    data.attributes.bannerImage.data.attributes.url;

  return (
    <>
      <SEO
        title={AboutData && AboutData.metaTitle}
        description={AboutData && AboutData.metaDescription}
        imageUrl={`${base_url}${bannerImageUrl}`} 
        url={`${base_url}/about"`}
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area */}
        <div
          className="slider-area slider-style-1 variation-default bg_image pt--25"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${base_url}${bannerImageUrl})`,
            height: "300px",
          }}
        >
          <div className="container pt--20">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  {logo && (
                    <Logo
                      image={`${base_url}${logo.url}`}
                      image2={`${base_url}${logo.url}`}
                    />
                  )}
                  <h2 className="subtitle theme-gradient mt-3">
                    {AboutData.title}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Content Area */}
        <div>
          <div dangerouslySetInnerHTML={{ __html: AboutData.content || "" }} />
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;

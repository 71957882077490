import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import ContactOne from "./ContactOne";
import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet";

const Contact = () => {
  useEffect(() => {
    const sanFrancisco = [37.7749, -122.4194];
    const montreal = [45.5017, -73.5673];
    const route = [sanFrancisco, montreal];

    // Set up the map options with an initial center and zoom
    const mapOptions = {
      center: sanFrancisco, // Set initial center to San Francisco
      zoom: 8, // Set an initial zoom level
    };

    // Create a custom icon for markers if needed
    const customIcon = L.icon({
      iconUrl:
        "https://icons.veryicon.com/png/o/application/wq/coordinate-6.png",
      iconSize: [32, 32],
      iconAnchor: [16, 32],
    });

    const map = L.map("map", mapOptions);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      map
    );

    // L.polyline(route, { color: 'blue' }).addTo(map);
    L.marker(sanFrancisco, { icon: customIcon }).addTo(map);
    L.marker(montreal, { icon: customIcon }).addTo(map);
  }, []);

  return (
    <>
      <SEO
        title="Contact Us"
        description="Welcome to our contact us page"
        imageUrl="https://www.seqnetworks.com/images/logo/favicon.png"
        url="https://www.seqnetworks.com/contact"
      />
      <Layout>
        {/* Background and title*/}
        <div
          className="slider-style-1"
          style={{
            backgroundColor: "black",
            height: "200px",
            textAlign: "center",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center pt--60">
                  <h1 className="title theme-gradient h2">Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <div id="map" style={{ height: "500px" }}></div>
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;

import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Logo from "../elements/logo/Logo";
// import manage from "../assets/images/manage.png";
import { base_url } from "../utils/base_url";
import PopupForm from "../elements/getInTouch/getInTouch";

const HomeDefault = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handlePopupToggle = () => {
    setShowPopup(!showPopup);
  };

  const [result, showResult] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    try {
      const response = await fetch(
        "https://cms.seqnetworks.com/api/contact-forms",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formObject),
        }
      );

      if (response.ok) {
        showResult(true);
        setTimeout(() => {
          showResult(false);
        }, 5000);
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }

    e.target.reset();
  };
  const [bannerData, setBannerData] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    fetch(`${base_url}/api/home?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        const { data } = responseData;
        setBannerData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch(`${base_url}/api/logos?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.data && responseData.data.length > 0) {
          setLogo(responseData.data[0].attributes.logoimage.data.attributes);
        }
      })
      .catch((error) => {
        console.error("Error fetching logo data:", error);
      });
  }, []);

  if (!bannerData) {
    return null; // Or any loading indicator if needed
  }

  const bannerImageUrl =
    bannerData &&
    bannerData.attributes &&
    bannerData.attributes.bannerImage &&
    bannerData.attributes.bannerImage.data.attributes &&
    bannerData.attributes.bannerImage.data.attributes.url;

  return (
    <>
      <SEO
        title={bannerData.attributes.metaTitle}
        description={bannerData.attributes.metaDescription}
        imageUrl={`${base_url}${bannerImageUrl}`}
        url={`${base_url}`}
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
        <div
          className="slider-area slider-style-1 variation-default bg_image pt--25"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${base_url}${bannerImageUrl})`,
            height: "450px",
          }}
        >
          <div className="container pt--20">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  {logo && (
                    <Logo
                      image={`${base_url}${logo.url}`}
                      image2={`${base_url}${logo.url}`}
                    />
                  )}
                  <h2 className="subtitle theme-gradient mt-3">
                    {bannerData.attributes.bannerTitle}
                  </h2>
                  <p className="description">
                    {bannerData.attributes.bannerText}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt--20">
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData.attributes.content || "",
            }}
          />

          <div className="rwt-callto-action-area rn-section-gapBottom">
            <div className="wrapper">
              <div className="rn-callto-action clltoaction-style-default style-5">
                <div className="container">
                  <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                      <div className="inner">
                        <div className="content text-center">
                          <div
                            className="animated fadeInUp"
                            style={{ animationDuration: "1s" }}
                          >
                            <h2 className="title">
                              Lets have a conversation about your IT needs
                            </h2>
                          </div>
                          <div
                            className="animated fadeInUp"
                            style={{ animationDuration: "1s" }}
                          >
                            <h6 className="subtitle"></h6>
                          </div>
                          <div
                            className="animated fadeInUp"
                            style={{ animationDuration: "1s" }}
                          >
                            <div className="call-to-btn text-center">
                              <a
                                className="btn-default btn-icon"
                                onClick={handlePopupToggle}
                              >
                                Get in touch
                                <i className="icon">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                  </svg>
                                </i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showPopup && (
            <PopupForm
              showPopup={showPopup}
              closePopup={handlePopupToggle}
              sendEmail={sendEmail}
              result={result}
            />
          )}
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default HomeDefault;

import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Logo from "../elements/logo/Logo";
import { base_url } from "../utils/base_url";

const HomeDefault = () => {
  const [bannerData, setBannerData] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    fetch(`${base_url}/api/home?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        const { data } = responseData;
        setBannerData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch(`${base_url}/api/logos?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.data && responseData.data.length > 0) {
          setLogo(responseData.data[0].attributes.logoimage.data.attributes);
        }
      })
      .catch((error) => {
        console.error("Error fetching logo data:", error);
      });
  }, []);

  if (!bannerData) {
    return null; // Or any loading indicator if needed
  }

  const bannerImageUrl =
    bannerData &&
    bannerData.attributes &&
    bannerData.attributes.bannerImage &&
    bannerData.attributes.bannerImage.data.attributes &&
    bannerData.attributes.bannerImage.data.attributes.url;

  return (
    <>
      <SEO
        title={bannerData.attributes.metaTitle}
        description={bannerData.attributes.metaDescription}
        imageUrl={`${base_url}${bannerImageUrl}`} // Assuming `metaImage` is an object with a `url` property
        url="https://www.seqnetworks.com"
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
        <div
          className="slider-area slider-style-1 variation-default bg_image pt--25"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${base_url}${bannerImageUrl})`,
            height: "450px",
          }}
        >
          <div className="container pt--20">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  {logo && (
                    <Logo
                      image={`${base_url}${logo.url}`}
                      image2={`${base_url}${logo.url}`}
                    />
                  )}
                  <h2 className="subtitle theme-gradient mt-3">
                    {bannerData.attributes.bannerTitle}
                  </h2>
                  <p className="description">
                    {bannerData.attributes.bannerText}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt--20">
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData.attributes.content || "",
            }}
          />
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default HomeDefault;

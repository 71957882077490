import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, imageUrl, robots = "index, follow", url }) => {
  console.log("title=>", title);
  // const defaultTitle = "static title";
  // const defaultDescription = "static images";
  // const defaultImageUrl = "https://www.seqnetworks.com/images/logo/favicon.png";
  // const defaultUrl = "https://www.seqnetworks.com/";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? `${title} || Sequoia Networks` : "defaultTitle"}</title>
        <meta name="robots" content={robots} />
        <meta name="description" content={description || "defaultDescription"} />
        <meta name="title" content={title || "defaultTitle"} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        {/* Open Graph Meta Tags m*/}
        <meta property="og:title" content={title || "defaultTitle"} />
        <meta
          property="og:description"
          content={description || "defaultDescription"}
        />
        <meta property="og:image" content={imageUrl || "defaultImageUrl"} />
        <meta property="og:url" content={url || "defaultUrl"} />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title || "defaultTitle"} />
        <meta
          name="twitter:description"
          content={description || defaultDescription}
        />
        <meta name="twitter:image" content={imageUrl || "defaultImageUrl"} />
        <meta name="twitter:url" content={url || "defaultUrl"} />
      </Helmet>
    </>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
};

export default SEO;
